export interface PlayListItem {
  name: string;
  url: string;
}

export const defaultPlayList: PlayListItem[] = [
    {name: 'RADWIMPS-天気の子-晴れゆく空', url: 'https://files.elmle.top/music/RADWIMPS-天気の子-晴れゆく空.flac'},
    {name: '陈奕迅-孤勇者-孤勇者', url: 'https://files.elmle.top/music/陈奕迅-孤勇者-孤勇者.flac'},
    {name: 'RADWIMPS-気の子-陽菜と、走る帆高', url: 'https://files.elmle.top/music/RADWIMPS-気の子-陽菜と、走る帆高.flac'},
    {name: 'Redfoo-New Thang-New Thang', url: 'https://files.elmle.top/music/Redfoo-New Thang-New Thang.flac'},
    {name: '谢军- 你不是我的月亮我不是你的云', url: 'https://files.elmle.top/music/谢军- 你不是我的月亮我不是你的云.mp3'},
    {name: 'ｄｊ心醉dj现场版', url: 'https://files.elmle.top/music/ｄｊ心醉dj现场版.mp3'},
    {name: '爱似水仙', url: 'https://files.elmle.top/music/爱似水仙.mp3'},
    {name: '渔舟唱晚', url: 'https://files.elmle.top/music/渔舟唱晚.mp3'},
    {name: '爱我就别伤害我 刘嘉亮 OK', url: 'https://files.elmle.top/music/爱我就别伤害我 刘嘉亮 OK.mp3'},
    {name: '此情可待(理查马克斯)', url: 'https://files.elmle.top/music/此情可待(理查马克斯).mp3'},
    {name: '丁当-我爱他', url: 'https://files.elmle.top/music/丁当-我爱他.mp3'},
    {name: '王菲-容易受伤的女人', url: 'https://files.elmle.top/music/王菲-容易受伤的女人.mp3'},
    {name: '巡逻兵进行曲(American Patrol)', url: 'https://files.elmle.top/music/巡逻兵进行曲(American Patrol).mp3'},
    {name: 'All I Want Is You', url: 'https://files.elmle.top/music/All I Want Is You.mp3'},
    {name: '宋家王朝(THE SOONG SISTER)', url: 'https://files.elmle.top/music/宋家王朝(THE SOONG SISTER).mp3'},
    {name: '许嵩-城府', url: 'https://files.elmle.top/music/许嵩-城府.mp3'},
    {name: '感觉静谧', url: 'https://files.elmle.top/music/感觉静谧.mp3'},
    {name: '火之战车(CHARIOTS OF FIRE)', url: 'https://files.elmle.top/music/火之战车(CHARIOTS OF FIRE).mp3'},
    {name: '王力宏-我们的歌', url: 'https://files.elmle.top/music/王力宏-我们的歌.mp3'},
    {name: 'CDImage', url: 'https://files.elmle.top/music/CDImage.wav'},
    {name: '伤心的爱(HEARTBREAK LOVE)', url: 'https://files.elmle.top/music/伤心的爱(HEARTBREAK LOVE).mp3'},
    {name: '谢军- 碎心石', url: 'https://files.elmle.top/music/谢军- 碎心石.mp3'},
    {name: '英雄的黎明', url: 'https://files.elmle.top/music/英雄的黎明.mp3'},
    {name: '黑小子', url: 'https://files.elmle.top/music/黑小子.mp3'},
    {name: '罗密欧与朱丽叶(ROMEO & JULIA)', url: 'https://files.elmle.top/music/罗密欧与朱丽叶.mp3'},
    {name: '许嵩-你若成风', url: 'https://files.elmle.top/music/许嵩-你若成风.mp3'},
    {name: '王菲-棋子1', url: 'https://files.elmle.top/music/王菲-棋子1.mp3'},
    {name: 'SHE-中国话', url: 'https://files.elmle.top/music/SHE-中国话.mp3'},
    {name: '星空（LYPHARD MELODY）', url: 'https://files.elmle.top/music/星空（LYPHARD MELODY）.mp3'},
    {name: '与狼共舞主题曲', url: 'https://files.elmle.top/music/与狼共舞主题曲.mp3'},
    {name: '希腊计划', url: 'https://files.elmle.top/music/希腊计划.mp3'},
    {name: '梦中的婚礼', url: 'https://files.elmle.top/music/梦中的婚礼.mp3'},
    {name: '你是爱(You Are Love)', url: 'https://files.elmle.top/music/你是爱(You Are Love).mp3'},
    {name: '巴比伦河(波尼姆)', url: 'https://files.elmle.top/music/巴比伦河(波尼姆).mp3'},
    {name: '王杰-一场游戏一场梦', url: 'https://files.elmle.top/music/王杰-一场游戏一场梦.mp3'},
    {name: '无尽的爱(莱昂里奇戴安娜罗斯)', url: 'https://files.elmle.top/music/无尽的爱(莱昂里奇戴安娜罗斯).mp3'},
    {name: 'Weepies_GottaHaveYou', url: 'https://files.elmle.top/music/Weepies_GottaHaveYou.mp3'},
    {name: '张靓颖-G大调的悲伤', url: 'https://files.elmle.top/music/张靓颖-G大调的悲伤.mp3'},
    {name: '爱的纪念', url: 'https://files.elmle.top/music/爱的纪念.mp3'},
    {name: 't.r.y-不是因为寂寞才想你', url: 'https://files.elmle.top/music/t.r.y-不是因为寂寞才想你.mp3'},
    {name: '拉德斯基进行曲', url: 'https://files.elmle.top/music/拉德斯基进行曲.mp3'},
    {name: '远处的星光(STARLIGHT AFAR)', url: 'https://files.elmle.top/music/远处的星光(STARLIGHT AFAR).mp3'},
    {name: 'If Youd Only Love Me', url: 'https://files.elmle.top/music/If Youd Only Love Me.mp3'},
    {name: '带走我的呼吸(柏林乐队)', url: 'https://files.elmle.top/music/带走我的呼吸(柏林乐队).mp3'},
    {name: '梁静茹-属于', url: 'https://files.elmle.top/music/梁静茹-属于.mp3'},
    {name: '绿色通道(GERRN SHANNEL)', url: 'https://files.elmle.top/music/绿色通道(GERRN SHANNEL).mp3'},
    {name: '萧人凤-仙剑问情- 仙剑', url: 'https://files.elmle.top/music/萧人凤-仙剑问情- 仙剑.mp3'},
    {name: '蔡依林-我知道你很难过', url: 'https://files.elmle.top/music/蔡依林-我知道你很难过.mp3'},
    {name: '加州旅馆(老鹰乐队)', url: 'https://files.elmle.top/music/加州旅馆(老鹰乐队).mp3'},
    {name: '安妮的仙境', url: 'https://files.elmle.top/music/安妮的仙境.mp3'},
    {name: '蔡依林-花蝴蝶', url: 'https://files.elmle.top/music/蔡依林-花蝴蝶.mp3'},
    {name: '你的笑颜(Your Smile)', url: 'https://files.elmle.top/music/你的笑颜(Your Smile).mp3'},
    {name: '查拉图斯特拉如是说', url: 'https://files.elmle.top/music/查拉图斯特拉如是说.mp3'},
    {name: '摇篮曲（舒伯特版）', url: 'https://files.elmle.top/music/摇篮曲（舒伯特版）.mp3'},
    {name: '航行(洛徳斯图尔特)', url: 'https://files.elmle.top/music/航行(洛徳斯图尔特).mp3'},
    {name: '奥林匹克鼓号曲', url: 'https://files.elmle.top/music/奥林匹克鼓号曲.mp3'},
    {name: '爱的协奏曲', url: 'https://files.elmle.top/music/爱的协奏曲.mp3'},
    {name: '威廉退尔', url: 'https://files.elmle.top/music/威廉退尔.mp3'},
    {name: '今夜何温馨(艾尔顿约翰)', url: 'https://files.elmle.top/music/今夜何温馨(艾尔顿约翰).mp3'},
    {name: '简单的礼物(Simple Gifts)', url: 'https://files.elmle.top/music/简单的礼物(Simple Gifts).mp3'},
    {name: 'sara-即使知道要见面', url: 'https://files.elmle.top/music/sara-即使知道要见面.mp3'},
    {name: 'apologize', url: 'https://files.elmle.top/music/apologize.mp3'},
    {name: 'CCTV动物世界片头曲(Just Blue)', url: 'https://files.elmle.top/music/CCTV动物世界片头曲(Just Blue).mp3'},
    {name: 'Windancer', url: 'https://files.elmle.top/music/Windancer.mp3'},
    {name: '乱世佳人', url: 'https://files.elmle.top/music/乱世佳人.mp3'},
    {name: '梦醒时分', url: 'https://files.elmle.top/music/梦醒时分.mp3'},
    {name: '拉拉爱', url: 'https://files.elmle.top/music/拉拉爱.mp3'},
    {name: '伍佰-挪威的森林', url: 'https://files.elmle.top/music/伍佰-挪威的森林.mp3'},
    {name: '周杰伦-夜曲', url: 'https://files.elmle.top/music/周杰伦-夜曲.mp3'},
    {name: 'you raise me up', url: 'https://files.elmle.top/music/you raise me up.mp3'},
    {name: '阿兰蒂斯之恋(ARANJUEZ MON AUOUR)', url: 'https://files.elmle.top/music/阿兰蒂斯之恋(ARANJUEZ MON AUOUR).mp3'},
    {name: '周杰伦-青花瓷', url: 'https://files.elmle.top/music/周杰伦-青花瓷.mp3'},
    {name: '甜蜜的一天(玛丽亚.凯丽&男人男孩)', url: 'https://files.elmle.top/music/甜蜜的一天(玛丽亚.凯丽&男人男孩).mp3'},
    {name: '摇篮曲（勃拉姆斯版）', url: 'https://files.elmle.top/music/摇篮曲（勃拉姆斯版）.mp3'},
    {name: '走过咖啡屋千百惠', url: 'https://files.elmle.top/music/走过咖啡屋千百惠.mp3'},
    {name: 'far away from home', url: 'https://files.elmle.top/music/far away from home.mp3'},
    {name: '周杰伦-珊瑚海', url: 'https://files.elmle.top/music/周杰伦-珊瑚海.mp3'},
    {name: 'honeymoney', url: 'https://files.elmle.top/music/honeymoney.mp3'},
    {name: '梦里的故事', url: 'https://files.elmle.top/music/梦里的故事.mp3'},
    {name: '昨日重现(卡本特)', url: 'https://files.elmle.top/music/昨日重现(卡本特).mp3'},
    {name: '张学友-饿狼传说', url: 'https://files.elmle.top/music/张学友-饿狼传说.mp3'},
    {name: '豪勇七蛟龙(The Magnificent Seven)', url: 'https://files.elmle.top/music/豪勇七蛟龙(The Magnificent Seven).mp3'},
    {name: '泪花(TEARS) ', url: 'https://files.elmle.top/music/泪花(TEARS) .mp3'},
    {name: '林俊杰-醉赤壁', url: 'https://files.elmle.top/music/林俊杰-醉赤壁.mp3'},
    {name: 'here i am', url: 'https://files.elmle.top/music/here i am.mp3'},
    {name: '周杰伦-千里之外', url: 'https://files.elmle.top/music/周杰伦-千里之外.mp3'},
    {name: '谢军- 新回心转意', url: 'https://files.elmle.top/music/谢军- 新回心转意.mp3'},
    {name: '得到你的人却得不到你的心', url: 'https://files.elmle.top/music/得到你的人却得不到你的心.mp3'},
    {name: '只要你过的比我好钟镇涛', url: 'https://files.elmle.top/music/只要你过的比我好钟镇涛.mp3'},
    {name: '爱的协奏曲(CONCERTO POUR UNR JEUNE FILLE NOMMEE)', url: 'https://files.elmle.top/music/爱的协奏曲(CONCERTO POUR UNR JEUNE FILLE NOMMEE).mp3'},
    {name: 'JohnMayer- Daughters', url: 'https://files.elmle.top/music/JohnMayer- Daughters.mp3'},
    {name: '奔放的旋律(正直兄弟)', url: 'https://files.elmle.top/music/奔放的旋律(正直兄弟).mp3'},
    {name: '梦飞船-不值得', url: 'https://files.elmle.top/music/梦飞船-不值得.mp3'},
    {name: '致爱丽丝(FOR ELFSE)', url: 'https://files.elmle.top/music/致爱丽丝(FOR ELFSE).mp3'},
    {name: '犯错', url: 'https://files.elmle.top/music/犯错.mp3'},
    {name: '月亮之上', url: 'https://files.elmle.top/music/月亮之上.mp3'},
    {name: '有你相伴(费丝.希尔)', url: 'https://files.elmle.top/music/有你相伴(费丝.希尔).mp3'},
    {name: '给母亲的信(LETTRE A MA MERE)', url: 'https://files.elmle.top/music/给母亲的信(LETTRE A MA MERE).mp3'},
    {name: '蝴蝶犬（Papillon）', url: 'https://files.elmle.top/music/蝴蝶犬（Papillon）.mp3'},
    {name: '每当我闭上双眼(娃娃脸)', url: 'https://files.elmle.top/music/每当我闭上双眼(娃娃脸).mp3'},
    {name: '王菲-棋子', url: 'https://files.elmle.top/music/王菲-棋子.mp3'},
    {name: '无心呢喃(威猛乐队)', url: 'https://files.elmle.top/music/无心呢喃(威猛乐队).mp3'},
    {name: '故乡的原风景', url: 'https://files.elmle.top/music/故乡的原风景.mp3'},
    {name: '大明宫词-序曲', url: 'https://files.elmle.top/music/大明宫词-序曲.mp3'},
    {name: '林俊杰-被风吹过的夏天', url: 'https://files.elmle.top/music/林俊杰-被风吹过的夏天.mp3'},
    {name: '流动(Flowing)', url: 'https://files.elmle.top/music/流动(Flowing).mp3'},
    {name: '今晚为爱祝贺', url: 'https://files.elmle.top/music/今晚为爱祝贺.mp3'},
    {name: '许嵩-清明雨上', url: 'https://files.elmle.top/music/许嵩-清明雨上.mp3'},
    {name: 'Party People Friday Night', url: 'https://files.elmle.top/music/Party People Friday Night.mp3'},
    {name: 'dj-moonlight shadow-groove coverage', url: 'https://files.elmle.top/music/dj-moonlight shadow-groove coverage.mp3'},
    {name: '英雄(玛丽亚.凯丽)', url: 'https://files.elmle.top/music/英雄(玛丽亚.凯丽).mp3'},
    {name: '张振宇-不要再来伤害我', url: 'https://files.elmle.top/music/张振宇-不要再来伤害我.mp3'},
    {name: '谢军- 爱上梦中人', url: 'https://files.elmle.top/music/谢军- 爱上梦中人.mp3'},
    {name: '卡布里的月光（moonlight of capri）', url: 'https://files.elmle.top/music/卡布里的月光（moonlight of capri）.mp3'},
    {name: '也会伤害我的心(阿隆.内维尔)', url: 'https://files.elmle.top/music/也会伤害我的心(阿隆.内维尔).mp3'},
    {name: '布兰诗歌', url: 'https://files.elmle.top/music/布兰诗歌.mp3'},
    {name: '一切都为你(布来恩亚当斯)', url: 'https://files.elmle.top/music/一切都为你(布来恩亚当斯).mp3'},
    {name: '12226477458129', url: 'https://files.elmle.top/music/12226477458129.mp3'},
    {name: '我情愿为你受一点伤 dj 萧军', url: 'https://files.elmle.top/music/我情愿为你受一点伤 dj 萧军.mp3'},
    {name: '泪洒天堂(艾力克克莱普顿)', url: 'https://files.elmle.top/music/泪洒天堂(艾力克克莱普顿).mp3'},
    {name: '以吻封缄(波比维顿)', url: 'https://files.elmle.top/music/以吻封缄(波比维顿).mp3'},
    {name: '薛之谦-认真的雪', url: 'https://files.elmle.top/music/薛之谦-认真的雪.mp3'},
    {name: '李圣杰-痴心绝对', url: 'https://files.elmle.top/music/李圣杰-痴心绝对.mp3'},
    {name: '谢军- 又一夜', url: 'https://files.elmle.top/music/谢军- 又一夜.mp3'},
    {name: '爱的罗曼史((Romance De Amor)', url: 'https://files.elmle.top/music/爱的罗曼史((Romance De Amor).mp3'},
    {name: '王绎龙-摇啊摇- djsunny', url: 'https://files.elmle.top/music/王绎龙-摇啊摇- djsunny.mp3'},
    {name: '再见警察(goodbye)', url: 'https://files.elmle.top/music/再见警察(goodbye).mp3'},
    {name: 'SHE-半糖主义', url: 'https://files.elmle.top/music/SHE-半糖主义.mp3'},
    {name: '爱情诺曼底黄征', url: 'https://files.elmle.top/music/爱情诺曼底黄征.mp3'},
    {name: '当男女相爱时(迈克鲍顿)', url: 'https://files.elmle.top/music/当男女相爱时(迈克鲍顿).mp3'},
    {name: 'Forgive', url: 'https://files.elmle.top/music/Forgive.mp3'},
    {name: '王力宏-唯一 (中文版)', url: 'https://files.elmle.top/music/王力宏-唯一 (中文版).mp3'},
    {name: '爱的筵席(LOVE SONGS FEAST)', url: 'https://files.elmle.top/music/爱的筵席(LOVE SONGS FEAST).mp3'},
    {name: '因为我爱你(沙金斯帝文)', url: 'https://files.elmle.top/music/因为我爱你(沙金斯帝文).mp3'},
    {name: 'beatles-hey jude', url: 'https://files.elmle.top/music/beatles-hey jude.mp3'},
    {name: '溜冰圆舞曲', url: 'https://files.elmle.top/music/溜冰圆舞曲.mp3'},
    {name: '林俊杰-不潮不用花钱', url: 'https://files.elmle.top/music/林俊杰-不潮不用花钱.mp3'},
    {name: '张靓颖-画心', url: 'https://files.elmle.top/music/张靓颖-画心.mp3'},
    {name: 'TGL', url: 'https://files.elmle.top/music/TGL.mp3'},
    {name: '蓝色的爱(LOVE IS BLUE)', url: 'https://files.elmle.top/music/蓝色的爱(LOVE IS BLUE).mp3'},
    {name: 'hideandseek', url: 'https://files.elmle.top/music/hideandseek.mp3'},
    {name: '行星组曲', url: 'https://files.elmle.top/music/行星组曲.mp3'},
    {name: '别让我担心(托尼.布莱克斯顿)', url: 'https://files.elmle.top/music/别让我担心(托尼.布莱克斯顿).mp3'},
    {name: '刘德华- 练习', url: 'https://files.elmle.top/music/刘德华- 练习.mp3'},
    {name: '梁靜茹-勇氣', url: 'https://files.elmle.top/music/梁靜茹-勇氣.mp3'},
    {name: '郑源-当我孤独的时候还可以抱着你', url: 'https://files.elmle.top/music/郑源-当我孤独的时候还可以抱着你.mp3'},
    {name: '星球大战主题曲', url: 'https://files.elmle.top/music/星球大战主题曲.mp3'},
    {name: 'gone with the sin', url: 'https://files.elmle.top/music/gone with the sin.mp3'},
    {name: 'better man', url: 'https://files.elmle.top/music/better man.mp3'},
    {name: '摇篮曲', url: 'https://files.elmle.top/music/摇篮曲.mp3'},
    {name: '情书(LOVELETTER TO YOU)', url: 'https://files.elmle.top/music/情书(LOVELETTER TO YOU).mp3'},
    {name: '周杰伦-听妈妈的话', url: 'https://files.elmle.top/music/周杰伦-听妈妈的话.mp3'},
    {name: '爸爸妈妈', url: 'https://files.elmle.top/music/爸爸妈妈.mp3'},
    {name: 'pub1189402127728', url: 'https://files.elmle.top/music/pub1189402127728.mp3'},
    {name: '你并不孤独(迈克尔.杰克逊)', url: 'https://files.elmle.top/music/你并不孤独(迈克尔.杰克逊).mp3'},
    {name: '爱情圣手(萨黛)', url: 'https://files.elmle.top/music/爱情圣手(萨黛).mp3'},
    {name: '月亮门(Moon gate)', url: 'https://files.elmle.top/music/月亮门(Moon gate).mp3'},
    {name: '张靓颖-你怎么舍得我难过', url: 'https://files.elmle.top/music/张靓颖-你怎么舍得我难过.mp3'},
    {name: '斯卡布罗集市(莎拉.布莱曼)', url: 'https://files.elmle.top/music/斯卡布罗集市(莎拉.布莱曼).mp3'},
    {name: 'Let The Sun Shine In', url: 'https://files.elmle.top/music/Let The Sun Shine In.mp3'},
    {name: 'wuding', url: 'https://files.elmle.top/music/wuding.mp3'},
    {name: '光辉岁月BEYOND', url: 'https://files.elmle.top/music/光辉岁月BEYOND.mp3'},
    {name: '张靓颖-我们说好的', url: 'https://files.elmle.top/music/张靓颖-我们说好的.mp3'},
    {name: '小号进行曲', url: 'https://files.elmle.top/music/小号进行曲.mp3'},
    {name: '夜莺(Ninghtingale)', url: 'https://files.elmle.top/music/夜莺(Ninghtingale).mp3'},
    {name: '寂静之声(西蒙加芬克尔)', url: 'https://files.elmle.top/music/寂静之声(西蒙加芬克尔).mp3'},
    {name: '林俊杰-小酒窝', url: 'https://files.elmle.top/music/林俊杰-小酒窝.mp3'},
    {name: '月光奏鸣曲（Piano Spnata）', url: 'https://files.elmle.top/music/月光奏鸣曲（Piano Spnata）.mp3'},
    {name: '阿罕布拉宫的回忆(Recuerdos de la Alhambra)', url: 'https://files.elmle.top/music/阿罕布拉宫的回忆(Recuerdos de la Alhambra).mp3'},
    {name: '晚秋黄凯芹', url: 'https://files.elmle.top/music/晚秋黄凯芹.mp3'},
    {name: 'CCTV对话主题曲', url: 'https://files.elmle.top/music/CCTV对话主题曲.mp3'},
    {name: '刘德华- 冰雨', url: 'https://files.elmle.top/music/刘德华- 冰雨.mp3'},
    {name: 'dj-冰河时代', url: 'https://files.elmle.top/music/dj-冰河时代.mp3'},
    {name: '神秘园之歌(Song From A Secret Garden)', url: 'https://files.elmle.top/music/神秘园之歌(Song From A Secret Garden).mp3'},
    {name: '梁静茹-分手快乐', url: 'https://files.elmle.top/music/梁静茹-分手快乐.mp3'},
    {name: '梁静茹-爱你不是两三天', url: 'https://files.elmle.top/music/梁静茹-爱你不是两三天.mp3'},
    {name: '周杰伦-甜甜的', url: 'https://files.elmle.top/music/周杰伦-甜甜的.mp3'},
    {name: 'Iwish', url: 'https://files.elmle.top/music/Iwish.mp3'},
    {name: '巴格达之星（Star Of Baghdad）', url: 'https://files.elmle.top/music/巴格达之星（Star Of Baghdad）.mp3'},
    {name: '阿木-有一种爱叫做放手', url: 'https://files.elmle.top/music/阿木-有一种爱叫做放手.mp3'},
    {name: '王力宏-改变自己', url: 'https://files.elmle.top/music/王力宏-改变自己.mp3'},
    {name: '舞曲yeyeye', url: 'https://files.elmle.top/music/舞曲yeyeye.mp3'},
    {name: 'Traveling Light', url: 'https://files.elmle.top/music/Traveling Light.mp3'},
    {name: 'CCTV动物世界片尾曲(we stay)', url: 'https://files.elmle.top/music/CCTV动物世界片尾曲(we stay).mp3'},
    {name: '和兰花在一起(With an orchid)', url: 'https://files.elmle.top/music/和兰花在一起(With an orchid).mp3'},
    {name: '雨中的旋律(贾森多诺南)', url: 'https://files.elmle.top/music/雨中的旋律(贾森多诺南).mp3'},
    {name: '小曲(Passacaglia)', url: 'https://files.elmle.top/music/小曲(Passacaglia).mp3'},
    {name: '柔板（Adagio）', url: 'https://files.elmle.top/music/柔板（Adagio）.mp3'},
    {name: '蔡依林-听说爱情回来过', url: 'https://files.elmle.top/music/蔡依林-听说爱情回来过.mp3'},
    {name: '陈瑞- 白狐', url: 'https://files.elmle.top/music/陈瑞- 白狐.mp3'},
    {name: '谢军- 那一夜', url: 'https://files.elmle.top/music/谢军- 那一夜.mp3'},
    {name: '出埃及记(Exodus)', url: 'https://files.elmle.top/music/出埃及记(Exodus).mp3'},
    {name: '童年(Childhood Memory)', url: 'https://files.elmle.top/music/童年(Childhood Memory).mp3'},
    {name: '刘欢-从头再来', url: 'https://files.elmle.top/music/刘欢-从头再来.mp3'},
    {name: '千里之外', url: 'https://files.elmle.top/music/千里之外.mp3'},
    {name: '谢军-心在跳情在烧', url: 'https://files.elmle.top/music/谢军-心在跳情在烧.mp3'},
    {name: '王力宏-你是我心内的一首歌', url: 'https://files.elmle.top/music/王力宏-你是我心内的一首歌.mp3'},
    {name: '世界无限大(艾美莉亚)', url: 'https://files.elmle.top/music/世界无限大(艾美莉亚).mp3'},
    {name: '明月千里寄相思', url: 'https://files.elmle.top/music/明月千里寄相思.mp3'},
    {name: '王菲-旋木', url: 'https://files.elmle.top/music/王菲-旋木.mp3'},
    {name: 'when i need you', url: 'https://files.elmle.top/music/when i need you.mp3'},
    {name: '去年圣诞(威猛乐队)', url: 'https://files.elmle.top/music/去年圣诞(威猛乐队).mp3'},
    {name: '张靓颖-想你,零点零一分', url: 'https://files.elmle.top/music/张靓颖-想你,零点零一分.mp3'},
    {name: '周杰伦-一路向北', url: 'https://files.elmle.top/music/周杰伦-一路向北.mp3'},
    {name: 'so20065211904832856', url: 'https://files.elmle.top/music/so20065211904832856.mp3'},
    {name: '雪的梦幻(Snowdreams)', url: 'https://files.elmle.top/music/雪的梦幻(Snowdreams).mp3'},
    {name: '王菲-执迷不悔(国语)', url: 'https://files.elmle.top/music/王菲-执迷不悔(国语).mp3'},
    {name: '爱之梦(LIEBESTRAUM)', url: 'https://files.elmle.top/music/爱之梦(LIEBESTRAUM).mp3'},
    {name: '只因你太美', url: 'https://files.elmle.top/music/只因你太美.mp3'},
    {name: '蔡琴-国语经典老歌-渡口', url: 'https://files.elmle.top/music/蔡琴-国语经典老歌-渡口.flac'},
    {name: '莫文蔚-这世界那么多人-这世界那么多人', url: 'https://files.elmle.top/music/莫文蔚-这世界那么多人-这世界那么多人.flac'},
    {name: '周杰伦 - 菊花台（钢琴版）', url: 'https://files.elmle.top/jaychou/2006 - 黄金甲 (EP)/ 周杰伦 - 菊花台（钢琴版）.flac'},
    {name: '周杰伦 - 黄金甲', url: 'https://files.elmle.top/jaychou/2006 - 黄金甲 (EP)/ 周杰伦 - 黄金甲.flac'},
    {name: '詹宇豪 - 鬥琴', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/05 詹宇豪 - 鬥琴.flac'},
    {name: '周杰倫 - 路小雨', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/15 周杰倫 - 路小雨.flac'},
    {name: '周杰倫 - 淡水海邊', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/04 周杰倫 - 淡水海邊.flac'},
    {name: '周杰倫 - 琴房', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/23 周杰倫 - 琴房.flac'},
    {name: 'Terdsak Janpan - Ride With Me', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/07 Terdsak Janpan - Ride With Me.flac'},
    {name: '周杰倫 - 不能說的秘密', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/25 周杰倫 - 不能說的秘密.flac'},
    {name: '周杰倫 - 早操', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/03 周杰倫 - 早操.flac'},
    {name: '周杰倫 - 晴天娃娃', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/12 周杰倫 - 晴天娃娃.flac'},
    {name: '周杰倫 - First Kiss', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/10 周杰倫 - First Kiss.flac'},
    {name: '周杰倫 - Secret (慢板)', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/18 周杰倫 - Secret (慢板).flac'},
    {name: 'Terdsak Janpan - Angel', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/19 Terdsak Janpan - Angel.flac'},
    {name: '周杰倫 - 阿郎與阿寶', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/13 周杰倫 - 阿郎與阿寶.flac'},
    {name: '周杰倫 - Secret (加長快板)', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/22 周杰倫 - Secret (加長快板).flac'},
    {name: '周杰倫 - 腳踏車', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/02 周杰倫 - 腳踏車.flac'},
    {name: 'Terdsak Janpan - Opening', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/01 Terdsak Janpan - Opening.flac'},
    {name: '周杰倫 - 小雨寫立可白Ⅱ', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/21 周杰倫 - 小雨寫立可白Ⅱ.flac'},
    {name: '周杰倫 - 小雨寫立可白Ⅰ', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/20 周杰倫 - 小雨寫立可白Ⅰ.flac'},
    {name: 'Terdsak Janpan - 與父共舞', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/14 Terdsak Janpan - 與父共舞.flac'},
    {name: 'Terdsak Janpan - Flash Back', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/17 Terdsak Janpan - Flash Back.flac'},
    {name: '周杰倫 - 湘倫小雨四手聯彈', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/06 周杰倫 - 湘倫小雨四手聯彈.flac'},
    {name: '周杰倫 - 女孩別為我哭泣', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/11 周杰倫 - 女孩別為我哭泣.flac'},
    {name: 'Camille Saint‐Saëns - The Swan', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/16 Camille Saint‐Saëns - The Swan.flac'},
    {name: '周杰倫 - 父與子', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/08 周杰倫 - 父與子.flac'},
    {name: '周杰倫 - Ending', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/24 周杰倫 - Ending.flac'},
    {name: '姚蘇蓉 - 情人的眼淚', url: 'https://files.elmle.top/jaychou/2007 - 《不能说的·秘密》电影原声带/09 姚蘇蓉 - 情人的眼淚.flac'},
    {name: '周杰倫 - 無雙', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/06 周杰倫 - 無雙.flac'},
    {name: '周杰倫 - 陽光宅男', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/04 周杰倫 - 陽光宅男.flac'},
    {name: '周杰倫 - 最長的電影', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/10 周杰倫 - 最長的電影.flac'},
    {name: '周杰倫 - 彩虹', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/02 周杰倫 - 彩虹.flac'},
    {name: '周杰倫 - 青花瓷', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/03 周杰倫 - 青花瓷.flac'},
    {name: '周杰倫 - 牛仔很忙', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/01 周杰倫 - 牛仔很忙.flac'},
    {name: '周杰倫 - 扯', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/08 周杰倫 - 扯.flac'},
    {name: '周杰倫 - 甜甜的', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/09 周杰倫 - 甜甜的.flac'},
    {name: '周杰倫 - 蒲公英的約定', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/05 周杰倫 - 蒲公英的約定.flac'},
    {name: '周杰倫 - 我不配', url: 'https://files.elmle.top/jaychou/2007 - 我很忙/07 周杰倫 - 我不配.flac'},
    {name: '周杰倫 - 千山万水', url: 'https://files.elmle.top/jaychou/单曲/ 周杰倫 - 千山万水.flac'},
    {name: '周杰倫 - 天地一鬥', url: 'https://files.elmle.top/jaychou/单曲/ 周杰倫 - 天地一鬥.flac'},
    {name: '周杰倫 - 畫沙', url: 'https://files.elmle.top/jaychou/单曲/ 周杰倫 - 畫沙.flac'},
    {name: '周杰倫 - 默', url: 'https://files.elmle.top/jaychou/单曲/ 周杰倫 - 默.flac'},
    {name: '周杰倫 - 周大俠', url: 'https://files.elmle.top/jaychou/单曲/ 周杰倫 - 周大俠.flac'},
    {name: '周杰倫 - Try', url: 'https://files.elmle.top/jaychou/单曲/ 周杰倫 - Try.flac'},
    {name: '周杰伦 - 说好的幸福呢+淘汰+青花瓷', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/15 周杰伦 - 说好的幸福呢+淘汰+青花瓷.flac'},
    {name: '周杰伦 - 龙战骑士', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/01 周杰伦 - 龙战骑士.flac'},
    {name: '周杰伦 - 魔术先生', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/08 周杰伦 - 魔术先生.flac'},
    {name: '周杰伦&浪花兄弟 - 你是我的OK绷', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/11 周杰伦&浪花兄弟 - 你是我的OK绷.flac'},
    {name: '周杰伦 - 威廉古堡', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/07 周杰伦 - 威廉古堡.flac'},
    {name: '周杰伦 - 跨时代', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/02 周杰伦 - 跨时代.flac'},
    {name: '周杰伦&袁咏琳 - 黑色幽默', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/09 周杰伦&袁咏琳 - 黑色幽默.flac'},
    {name: '周杰伦 - 龙卷风', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/14 周杰伦 - 龙卷风.flac'},
    {name: '浪花兄弟 - 想你就写信', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/10 浪花兄弟 - 想你就写信.flac'},
    {name: '周杰伦 - 稻香', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/12 周杰伦 - 稻香.flac'},
    {name: '周杰伦 - 爱在西元前', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/04 周杰伦 - 爱在西元前.flac'},
    {name: '周杰伦&梁心颐 - 蛇舞', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/03 周杰伦&梁心颐 - 蛇舞.flac'},
    {name: '周杰伦 - 我不配', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/05 周杰伦 - 我不配.flac'},
    {name: '周杰伦 - 阳光宅男', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/13 周杰伦 - 阳光宅男.flac'},
    {name: '周杰伦 - 嘻哈空姐', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 1/06 周杰伦 - 嘻哈空姐.flac'},
    {name: '周杰伦&杨瑞代 - 爱的飞行日记', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/05 周杰伦&杨瑞代 - 爱的飞行日记.flac'},
    {name: '周杰伦 - 以父之名', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/06 周杰伦 - 以父之名.flac'},
    {name: '周杰伦 - 双截棍', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/09 周杰伦 - 双截棍.flac'},
    {name: '周杰伦 - 时光机', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/02 周杰伦 - 时光机.flac'},
    {name: '周杰伦 - 爸,我回来了+心事谁人知', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/03 周杰伦 - 爸,我回来了+心事谁人知.flac'},
    {name: '周杰伦 - 东风破', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/08 周杰伦 - 东风破.flac'},
    {name: '周杰伦 - 雨下一整晚', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/04 周杰伦 - 雨下一整晚.flac'},
    {name: '周杰伦 - 免费教学录影带', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/01 周杰伦 - 免费教学录影带.flac'},
    {name: '周杰伦 - 开不了口', url: 'https://files.elmle.top/jaychou/2010 - 超时代世界巡回演唱会/CD 2/07 周杰伦 - 开不了口.flac'},
    {name: '周杰倫 - 黑色幽默', url: 'https://files.elmle.top/jaychou/2000 - Jay/06 周杰倫 - 黑色幽默.flac'},
    {name: '周杰倫 - 星晴', url: 'https://files.elmle.top/jaychou/2000 - Jay/03 周杰倫 - 星晴.flac'},
    {name: '周杰倫 - 鬥牛', url: 'https://files.elmle.top/jaychou/2000 - Jay/05 周杰倫 - 鬥牛.flac'},
    {name: '周杰倫 - 印地安老斑鳩', url: 'https://files.elmle.top/jaychou/2000 - Jay/08 周杰倫 - 印地安老斑鳩.flac'},
    {name: '周杰倫 - 娘子', url: 'https://files.elmle.top/jaychou/2000 - Jay/04 周杰倫 - 娘子.flac'},
    {name: '周杰倫 - 龍捲風', url: 'https://files.elmle.top/jaychou/2000 - Jay/09 周杰倫 - 龍捲風.flac'},
    {name: '周杰倫 - 伊斯坦堡', url: 'https://files.elmle.top/jaychou/2000 - Jay/07 周杰倫 - 伊斯坦堡.flac'},
    {name: '周杰倫 - 完美主義', url: 'https://files.elmle.top/jaychou/2000 - Jay/02 周杰倫 - 完美主義.flac'},
    {name: '周杰倫 - 可愛女人', url: 'https://files.elmle.top/jaychou/2000 - Jay/01 周杰倫 - 可愛女人.flac'},
    {name: '周杰倫 - 反方向的鐘', url: 'https://files.elmle.top/jaychou/2000 - Jay/10 周杰倫 - 反方向的鐘.flac'},
    {name: '周杰倫 - 東風破', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/05 周杰倫 - 東風破.flac'},
    {name: '周杰倫 - 梯田', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/10 周杰倫 - 梯田.flac'},
    {name: '周杰倫 - 懦夫', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/02 周杰倫 - 懦夫.flac'},
    {name: '周杰倫 - 三年二班', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/04 周杰倫 - 三年二班.flac'},
    {name: '周杰倫 - 愛情懸崖', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/09 周杰倫 - 愛情懸崖.flac'},
    {name: '周杰倫 - 她的睫毛', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/08 周杰倫 - 她的睫毛.flac'},
    {name: '周杰倫 - 同一種調調', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/07 周杰倫 - 同一種調調.flac'},
    {name: '周杰倫 - 妳聽得到', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/06 周杰倫 - 妳聽得到.flac'},
    {name: '周杰倫 - 晴天', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/03 周杰倫 - 晴天.flac'},
    {name: '周杰倫 - 雙刀', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/11 周杰倫 - 雙刀.flac'},
    {name: '周杰倫 - 以父之名', url: 'https://files.elmle.top/jaychou/2003 - 叶惠美/01 周杰倫 - 以父之名.flac'},
    {name: '周杰倫 - 超跑女神', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/11 周杰倫 - 超跑女神.flac'},
    {name: '周杰倫 - 驚嘆號', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/01 周杰倫 - 驚嘆號.flac'},
    {name: '周杰倫 - 公主病', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/04 周杰倫 - 公主病.flac'},
    {name: '周杰倫 - 迷魂曲', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/02 周杰倫 - 迷魂曲.flac'},
    {name: '周杰倫 - 你好嗎', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/05 周杰倫 - 你好嗎.flac'},
    {name: '周杰倫 - Mine Mine', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/03 周杰倫 - Mine Mine.flac'},
    {name: '周杰倫 - 琴傷', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/07 周杰倫 - 琴傷.flac'},
    {name: '周杰倫 - 世界未末日', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/09 周杰倫 - 世界未末日.flac'},
    {name: '周杰倫 - 水手怕水', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/08 周杰倫 - 水手怕水.flac'},
    {name: '周杰倫 - 皮影戲', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/10 周杰倫 - 皮影戲.flac'},
    {name: '周杰倫 - 療傷燒肉粽', url: 'https://files.elmle.top/jaychou/2011 - 惊叹号/06 周杰倫 - 療傷燒肉粽.flac'},
    {name: '周杰倫 - 蝸牛', url: 'https://files.elmle.top/jaychou/2001 - FANTASY PLUS (EP)/01 周杰倫 - 蝸牛.flac'},
    {name: '周杰倫 - 你比從前快樂', url: 'https://files.elmle.top/jaychou/2001 - FANTASY PLUS (EP)/02 周杰倫 - 你比從前快樂.flac'},
    {name: '周杰倫 - 世界末日', url: 'https://files.elmle.top/jaychou/2001 - FANTASY PLUS (EP)/03 周杰倫 - 世界末日.flac'},
    {name: '周杰倫 - 霍元甲', url: 'https://files.elmle.top/jaychou/2006 - 霍元甲 (EP)/01 周杰倫 - 霍元甲.flac'},
    {name: '周杰倫 - 獻世(Live)', url: 'https://files.elmle.top/jaychou/2006 - 霍元甲 (EP)/02 周杰倫 - 獻世(Live).flac'},
    {name: '周杰倫 - 四季列车', url: 'https://files.elmle.top/jaychou/2012 - 12新作/01 周杰倫 - 四季列车.flac'},
    {name: '周杰倫 - 红尘客栈', url: 'https://files.elmle.top/jaychou/2012 - 12新作/08 周杰倫 - 红尘客栈.flac'},
    {name: '周杰倫 - 比较大的大提琴', url: 'https://files.elmle.top/jaychou/2012 - 12新作/06 周杰倫 - 比较大的大提琴.flac'},
    {name: '周杰倫 - 爱你没差', url: 'https://files.elmle.top/jaychou/2012 - 12新作/07 周杰倫 - 爱你没差.flac'},
    {name: '周杰倫 - 哪里都是你', url: 'https://files.elmle.top/jaychou/2012 - 12新作/11 周杰倫 - 哪里都是你.flac'},
    {name: '周杰倫 - 乌克丽丽', url: 'https://files.elmle.top/jaychou/2012 - 12新作/12 周杰倫 - 乌克丽丽.flac'},
    {name: '周杰倫 - 大笨钟', url: 'https://files.elmle.top/jaychou/2012 - 12新作/10 周杰倫 - 大笨钟.flac'},
    {name: '周杰倫 - 梦想启动', url: 'https://files.elmle.top/jaychou/2012 - 12新作/09 周杰倫 - 梦想启动.flac'},
    {name: '周杰倫 - 明明就', url: 'https://files.elmle.top/jaychou/2012 - 12新作/04 周杰倫 - 明明就.flac'},
    {name: '周杰倫 - 傻笑', url: 'https://files.elmle.top/jaychou/2012 - 12新作/05 周杰倫 - 傻笑.flac'},
    {name: '周杰倫 - 公公偏头痛', url: 'https://files.elmle.top/jaychou/2012 - 12新作/03 周杰倫 - 公公偏头痛.flac'},
    {name: '周杰倫 - 手语', url: 'https://files.elmle.top/jaychou/2012 - 12新作/02 周杰倫 - 手语.flac'},
    {name: '周杰倫 - 斷了的弦', url: 'https://files.elmle.top/jaychou/2003 - 寻找周杰伦 (EP)/02 周杰倫 - 斷了的弦.flac'},
    {name: '周杰倫 - 軌跡', url: 'https://files.elmle.top/jaychou/2003 - 寻找周杰伦 (EP)/03 周杰倫 - 軌跡.flac'},
    {name: '周杰倫 - 軌跡', url: 'https://files.elmle.top/jaychou/2003 - 寻找周杰伦 (EP)/01 周杰倫 - 軌跡.flac'},
    {name: '周杰倫 - 斷了的弦', url: 'https://files.elmle.top/jaychou/2003 - 寻找周杰伦 (EP)/04 周杰倫 - 斷了的弦.flac'},
    {name: '周杰倫 - 外婆', url: 'https://files.elmle.top/jaychou/2004 - 七里香/04 周杰倫 - 外婆.flac'},
    {name: '周杰倫 - 將軍', url: 'https://files.elmle.top/jaychou/2004 - 七里香/05 周杰倫 - 將軍.flac'},
    {name: '周杰倫 - 擱淺', url: 'https://files.elmle.top/jaychou/2004 - 七里香/06 周杰倫 - 擱淺.flac'},
    {name: '周杰倫 - 亂舞春秋', url: 'https://files.elmle.top/jaychou/2004 - 七里香/07 周杰倫 - 亂舞春秋.flac'},
    {name: '周杰倫 - 止戰之殤', url: 'https://files.elmle.top/jaychou/2004 - 七里香/10 周杰倫 - 止戰之殤.flac'},
    {name: '周杰倫 - 藉口', url: 'https://files.elmle.top/jaychou/2004 - 七里香/03 周杰倫 - 藉口.flac'},
    {name: '周杰倫 - 園遊會', url: 'https://files.elmle.top/jaychou/2004 - 七里香/09 周杰倫 - 園遊會.flac'},
    {name: '周杰倫 - 困獸之鬥', url: 'https://files.elmle.top/jaychou/2004 - 七里香/08 周杰倫 - 困獸之鬥.flac'},
    {name: '周杰倫 - 我的地盤', url: 'https://files.elmle.top/jaychou/2004 - 七里香/01 周杰倫 - 我的地盤.flac'},
    {name: '周杰倫 - 七里香', url: 'https://files.elmle.top/jaychou/2004 - 七里香/02 周杰倫 - 七里香.flac'},
    {name: '周杰倫 - 明明就', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/01 周杰倫 - 明明就.flac'},
    {name: '周杰倫 - 青花瓷', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/05 周杰倫 - 青花瓷.flac'},
    {name: '周杰倫 - 開不了口', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/09 周杰倫 - 開不了口.flac'},
    {name: '周杰倫 - 公公偏頭痛', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/04 周杰倫 - 公公偏頭痛.flac'},
    {name: '周杰倫 - 彩虹+軌跡', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/07 周杰倫 - 彩虹+軌跡.flac'},
    {name: '周杰倫 - 烏克麗麗', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/10 周杰倫 - 烏克麗麗.flac'},
    {name: '周杰倫 - Mine Mine', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/02 周杰倫 - Mine Mine.flac'},
    {name: '周杰倫 - 鬥牛+水手怕水+大笨鐘', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/06 周杰倫 - 鬥牛+水手怕水+大笨鐘.flac'},
    {name: '周杰倫 - 龍捲風', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/03 周杰倫 - 龍捲風.flac'},
    {name: '周杰倫 - 手語', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/08 周杰倫 - 手語.flac'},
    {name: '周杰倫 - 陽光宅男', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD2/11 周杰倫 - 陽光宅男.flac'},
    {name: '周杰倫 - 天台', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/04 周杰倫 - 天台.flac'},
    {name: '周杰倫 - 打架舞', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/07 周杰倫 - 打架舞.flac'},
    {name: '周杰倫 - 驚嘆號', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/01 周杰倫 - 驚嘆號.flac'},
    {name: '周杰倫 - 哪裡都是你', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/08 周杰倫 - 哪裡都是你.flac'},
    {name: '周杰倫 - 快門慢舞', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/06 周杰倫 - 快門慢舞.flac'},
    {name: '周杰倫 - 雙截棍', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/11 周杰倫 - 雙截棍.flac'},
    {name: '周杰倫 - 比較大的大提琴', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/05 周杰倫 - 比較大的大提琴.flac'},
    {name: '周杰倫 - 龍拳', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/02 周杰倫 - 龍拳.flac'},
    {name: '周杰倫 - 最後的戰役', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/03 周杰倫 - 最後的戰役.flac'},
    {name: '周杰倫 - 不能說的秘密', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/10 周杰倫 - 不能說的秘密.flac'},
    {name: '周杰倫 - 一路向北', url: 'https://files.elmle.top/jaychou/2016 - 魔天伦世界巡回演唱会/CD1/09 周杰倫 - 一路向北.flac'},
    {name: '周杰倫 - 你听得到', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/05 周杰倫 - 你听得到.flac'},
    {name: '周杰倫 - 晴天', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/04 周杰倫 - 晴天.flac'},
    {name: '周杰倫 - 亂舞春秋', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/10 周杰倫 - 亂舞春秋.flac'},
    {name: '周杰倫 - 梯田、爸我回來了', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/06 周杰倫 - 梯田、爸我回來了.flac'},
    {name: '周杰倫 - 龍卷风', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/08 周杰倫 - 龍卷风.flac'},
    {name: '周杰倫 - 愛情懸崖', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/13 周杰倫 - 愛情懸崖.flac'},
    {name: '周杰倫 - 我的地盤', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/12 周杰倫 - 我的地盤.flac'},
    {name: '周杰倫 - 她的睫毛', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/03 周杰倫 - 她的睫毛.flac'},
    {name: '周杰倫 - 星晴、回到過去、最后的戰役、愛我別走', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/11 周杰倫 - 星晴、回到過去、最后的戰役、愛我別走.flac'},
    {name: '周杰倫 - 以父之名', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/01 周杰倫 - 以父之名.flac'},
    {name: '周杰倫 - 將軍', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/09 周杰倫 - 將軍.flac'},
    {name: '周杰倫 - 園游', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/07 周杰倫 - 園游.flac'},
    {name: '周杰倫 - 止戰之殤', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 1/02 周杰倫 - 止戰之殤.flac'},
    {name: '周杰倫 - 雙刀、雙截棍、龍拳', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/04 周杰倫 - 雙刀、雙截棍、龍拳.flac'},
    {name: '周杰倫 - 斷了的弦', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/10 周杰倫 - 斷了的弦.flac'},
    {name: '周杰倫 - 七里香', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/08 周杰倫 - 七里香.flac'},
    {name: '周杰倫 - 軌跡', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/12 周杰倫 - 軌跡.flac'},
    {name: '周杰倫 - 藉口', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/02 周杰倫 - 藉口.flac'},
    {name: '周杰倫 - 簡單愛', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/07 周杰倫 - 簡單愛.flac'},
    {name: '周杰倫 - 擱淺', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/01 周杰倫 - 擱淺.flac'},
    {name: '周杰倫 - 倒帶', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/06 周杰倫 - 倒帶.flac'},
    {name: '周杰倫 - 東風破', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/11 周杰倫 - 東風破.flac'},
    {name: '周杰倫 - 外婆', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/09 周杰倫 - 外婆.flac'},
    {name: '周杰倫 - 困獸之斗', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/05 周杰倫 - 困獸之斗.flac'},
    {name: '周杰倫 - 瓦解', url: 'https://files.elmle.top/jaychou/2004 - 无与伦比演唱会/CD 2/03 周杰倫 - 瓦解.flac'},
    {name: '周杰倫 - 暗号', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/04 周杰倫 - 暗号.flac'},
    {name: '周杰倫 - 斗牛', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/08 周杰倫 - 斗牛.flac'},
    {name: '周杰倫 - Opening 双截棍(变奏版)', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/01 周杰倫 - Opening 双截棍(变奏版).flac'},
    {name: '周杰倫 - 分裂', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/09 周杰倫 - 分裂.flac'},
    {name: '周杰倫 - 忍者', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/02 周杰倫 - 忍者.flac'},
    {name: '周杰倫 - 爷爷泡的茶(加长版)', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/03 周杰倫 - 爷爷泡的茶(加长版).flac'},
    {name: '周杰倫 - 回到过去', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/07 周杰倫 - 回到过去.flac'},
    {name: '周杰倫 - 半岛铁盒', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/06 周杰倫 - 半岛铁盒.flac'},
    {name: '周杰倫 - 爱在西元前', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/05 周杰倫 - 爱在西元前.flac'},
    {name: '周杰倫 - 黑色幽默', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/11 周杰倫 - 黑色幽默.flac'},
    {name: '周杰倫 - 安静', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 1/10 周杰倫 - 安静.flac'},
    {name: '周杰倫 - 找自己', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/01 周杰倫 - 找自己.flac'},
    {name: '周杰倫 - 星晴', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/03 周杰倫 - 星晴.flac'},
    {name: '周杰倫 - 简单爱', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/08 周杰倫 - 简单爱.flac'},
    {name: '周杰倫 - 上海一九四三', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/05 周杰倫 - 上海一九四三.flac'},
    {name: '周杰倫 - 龙拳', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/04 周杰倫 - 龙拳.flac'},
    {name: '周杰倫 - 龙卷风', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/02 周杰倫 - 龙卷风.flac'},
    {name: '周杰倫 - 你怎么连话都说不清楚', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/06 周杰倫 - 你怎么连话都说不清楚.flac'},
    {name: '周杰倫 - 开不了口', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/09 周杰倫 - 开不了口.flac'},
    {name: '周杰倫 - 最后的战役', url: 'https://files.elmle.top/jaychou/2002 - The One 演唱会/CD 2/07 周杰倫 - 最后的战役.flac'},
    {name: '周杰倫 - 四面楚歌', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/05 周杰倫 - 四面楚歌.flac'},
    {name: '周杰倫 - 藍色風暴', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/02 周杰倫 - 藍色風暴.flac'},
    {name: '周杰倫 - 黑色毛衣', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/04 周杰倫 - 黑色毛衣.flac'},
    {name: '周杰倫 - 髮如雪', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/03 周杰倫 - 髮如雪.flac'},
    {name: '周杰倫 - 夜曲', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/01 周杰倫 - 夜曲.flac'},
    {name: '周杰倫 - 珊瑚海', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/10 周杰倫 - 珊瑚海.flac'},
    {name: '周杰倫 - 楓', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/06 周杰倫 - 楓.flac'},
    {name: '周杰倫 - 飄移', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/11 周杰倫 - 飄移.flac'},
    {name: '周杰倫 - 浪漫手機', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/07 周杰倫 - 浪漫手機.flac'},
    {name: '周杰倫 - 麥芽糖', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/09 周杰倫 - 麥芽糖.flac'},
    {name: '周杰倫 - 逆鱗', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/08 周杰倫 - 逆鱗.flac'},
    {name: '周杰倫 - 一路向北', url: 'https://files.elmle.top/jaychou/2005 - 11月的萧邦/12 周杰倫 - 一路向北.flac'},
    {name: '周杰倫 - 龍拳', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/04 周杰倫 - 龍拳.flac'},
    {name: '周杰倫 - 暗號', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/03 周杰倫 - 暗號.flac'},
    {name: '周杰倫 - 回到過去', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/08 周杰倫 - 回到過去.flac'},
    {name: '周杰倫 - 最後的戰役', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/10 周杰倫 - 最後的戰役.flac'},
    {name: '周杰倫 - 火車叨位去', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/05 周杰倫 - 火車叨位去.flac'},
    {name: '周杰倫 - 爺爺泡的茶', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/07 周杰倫 - 爺爺泡的茶.flac'},
    {name: '周杰倫 - 半獸人', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/01 周杰倫 - 半獸人.flac'},
    {name: '周杰倫 - 半島鐵盒', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/02 周杰倫 - 半島鐵盒.flac'},
    {name: '周杰倫 - 米蘭的小鐵匠', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/09 周杰倫 - 米蘭的小鐵匠.flac'},
    {name: '周杰倫 - 分裂', url: 'https://files.elmle.top/jaychou/2002 - 八度空间/06 周杰倫 - 分裂.flac'},
    {name: '周杰倫 - 波爺', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/03 周杰倫 - 波爺.flac'},
    {name: '周杰倫 - 悲劇的序幕', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/31 周杰倫 - 悲劇的序幕.flac'},
    {name: '周杰倫 - 哪裡都是你', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/29 周杰倫 - 哪裡都是你.flac'},
    {name: '周杰倫 - 骰子', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/20 周杰倫 - 骰子.flac'},
    {name: '周杰倫 - 美術館', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/01 周杰倫 - 美術館.flac'},
    {name: '周杰倫 - IF', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/10 周杰倫 - IF.flac'},
    {name: '周杰倫 - Goodbye', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/34 周杰倫 - Goodbye.flac'},
    {name: '周杰倫 - 保齡男孩', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/02 周杰倫 - 保齡男孩.flac'},
    {name: '周杰倫 - 亡命追逐', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/33 周杰倫 - 亡命追逐.flac'},
    {name: '周杰倫 - 英雄之歌', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/24 周杰倫 - 英雄之歌.flac'},
    {name: '周杰倫 - 兄弟的安慰', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/30 周杰倫 - 兄弟的安慰.flac'},
    {name: '周杰倫 - 天台的月光 (吉他版)', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/35 周杰倫 - 天台的月光 (吉他版).flac'},
    {name: '周杰倫 - 無助', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/32 周杰倫 - 無助.flac'},
    {name: '周杰倫 - 浪子膏', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/05 周杰倫 - 浪子膏.flac'},
    {name: '周杰倫 - 天台的月光', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/25 周杰倫 - 天台的月光.flac'},
    {name: '周杰倫 - 狗仔舞', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/27 周杰倫 - 狗仔舞.flac'},
    {name: '周杰倫 - 打架舞', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/09 周杰倫 - 打架舞.flac'},
    {name: '周杰倫 - 錢難賺', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/15 周杰倫 - 錢難賺.flac'},
    {name: '周杰倫 - Strike”', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/13 周杰倫 - Strike”.flac'},
    {name: '周杰倫 - 美景', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/04 周杰倫 - 美景.flac'},
    {name: '周杰倫 - 竹竿舞', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/26 周杰倫 - 竹竿舞.flac'},
    {name: '周杰倫 - 大明星', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/21 周杰倫 - 大明星.flac'},
    {name: '周杰倫 - 快門慢舞', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/11 周杰倫 - 快門慢舞.flac'},
    {name: '周杰倫 - 威少的陷阱', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/28 周杰倫 - 威少的陷阱.flac'},
    {name: '周杰倫 - 天台', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/06 周杰倫 - 天台.flac'},
    {name: '周杰倫 - 熱血碼頭', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/14 周杰倫 - 熱血碼頭.flac'},
    {name: '周杰倫 - 逛夜市', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/17 周杰倫 - 逛夜市.flac'},
    {name: '周杰倫 - 情人湖夜市', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/18 周杰倫 - 情人湖夜市.flac'},
    {name: '周杰倫 - 水管的友情', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/16 周杰倫 - 水管的友情.flac'},
    {name: '周杰倫 - 撈金魚', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/19 周杰倫 - 撈金魚.flac'},
    {name: '周杰倫 - 屋頂上的願望', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/22 周杰倫 - 屋頂上的願望.flac'},
    {name: '周杰倫 - You\'re My Everything', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/23 周杰倫 - You\'re My Everything.flac'},
    {name: '周杰倫 - 龍門澡堂', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/08 周杰倫 - 龍門澡堂.flac'},
    {name: '周杰倫 - 阿郎的童年', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/12 周杰倫 - 阿郎的童年.flac'},
    {name: '周杰倫 - 氣勢', url: 'https://files.elmle.top/jaychou/2013 - 《天台爱情》电影原声带/07 周杰倫 - 氣勢.flac'},
    {name: '周杰倫 - 愛在西元前', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/04 周杰倫 - 愛在西元前.flac'},
    {name: '江蕙 - 落雨聲', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/07 江蕙 - 落雨聲.flac'},
    {name: '周杰倫 - 反方向的鐘', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/14 周杰倫 - 反方向的鐘.flac'},
    {name: '許茹芸 - 禁止悲傷', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/03 許茹芸 - 禁止悲傷.flac'},
    {name: '周杰倫 - 娘子', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/09 周杰倫 - 娘子.flac'},
    {name: '李玟 - 刀馬旦', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/01 李玟 - 刀馬旦.flac'},
    {name: '周杰倫 - 上海一九四三', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/02 周杰倫 - 上海一九四三.flac'},
    {name: '康康 - 電燈泡', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/10 康康 - 電燈泡.flac'},
    {name: '康康 - 電燈泡II', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/11 康康 - 電燈泡II.flac'},
    {name: '溫嵐 - 眼淚知道', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/05 溫嵐 - 眼淚知道.flac'},
    {name: '古巨基 - 夜空的精靈', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/06 古巨基 - 夜空的精靈.flac'},
    {name: '溫嵐 - 動心', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/08 溫嵐 - 動心.flac'},
    {name: '溫嵐 - 胡同里有只貓', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/13 溫嵐 - 胡同里有只貓.flac'},
    {name: '吳宗憲 - 你比從前快樂', url: 'https://files.elmle.top/jaychou/2002 - 拍档 (周杰伦-方文山联手打造精选)/12 吳宗憲 - 你比從前快樂.flac'},
    {name: '周杰伦 - 菊花台', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/09 周杰伦 - 菊花台.flac'},
    {name: '周杰伦 - 蒲公英的约定', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/07 周杰伦 - 蒲公英的约定.flac'},
    {name: '周杰伦 - 麦芽糖', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/10 周杰伦 - 麦芽糖.flac'},
    {name: '周杰伦 - 黄金甲', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/01 周杰伦 - 黄金甲.flac'},
    {name: '周杰伦 - 甜甜的', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/05 周杰伦 - 甜甜的.flac'},
    {name: '周杰伦 - 最长的电影', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/06 周杰伦 - 最长的电影.flac'},
    {name: '周杰伦 - 退后', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/04 周杰伦 - 退后.flac'},
    {name: '周杰伦 - 不能说的秘密', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/03 周杰伦 - 不能说的秘密.flac'},
    {name: '周杰伦 - 无双', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/02 周杰伦 - 无双.flac'},
    {name: '周杰伦 - 白色风车', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/08 周杰伦 - 白色风车.flac'},
    {name: '周杰伦 - 听妈妈的话', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/12 周杰伦 - 听妈妈的话.flac'},
    {name: '周杰伦 - 牛仔很忙', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 1/11 周杰伦 - 牛仔很忙.flac'},
    {name: '周杰伦 - 双截棍', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/08 周杰伦 - 双截棍.flac'},
    {name: '周杰伦 - 发如雪', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/06 周杰伦 - 发如雪.flac'},
    {name: '周杰伦 - 周大侠', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/09 周杰伦 - 周大侠.flac'},
    {name: '周杰伦 - 迷迭香', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/04 周杰伦 - 迷迭香.flac'},
    {name: '周杰伦 - 阳光宅男', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/05 周杰伦 - 阳光宅男.flac'},
    {name: '周杰伦 - 夜曲', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/03 周杰伦 - 夜曲.flac'},
    {name: '周杰伦 - 本草纲目', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/01 周杰伦 - 本草纲目.flac'},
    {name: '周杰伦 - 千里之外', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/02 周杰伦 - 千里之外.flac'},
    {name: '周杰伦 - 霍元甲', url: 'https://files.elmle.top/jaychou/2007 - 世界巡回 (台北站) 演唱会/CD 2/07 周杰伦 - 霍元甲.flac'},
    {name: '周杰倫 - 說了再見', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/02 周杰倫 - 說了再見.flac'},
    {name: '周杰倫 - 煙花易冷', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/03 周杰倫 - 煙花易冷.flac'},
    {name: '周杰倫 - 自導自演', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/10 周杰倫 - 自導自演.flac'},
    {name: '周杰倫 - 雨下一整晚', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/06 周杰倫 - 雨下一整晚.flac'},
    {name: '周杰倫 - 免費教學錄影帶', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/04 周杰倫 - 免費教學錄影帶.flac'},
    {name: '周杰倫 - 好久不見', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/05 周杰倫 - 好久不見.flac'},
    {name: '周杰倫 - 跨時代', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/01 周杰倫 - 跨時代.flac'},
    {name: '周杰倫 - 超人不會飛', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/11 周杰倫 - 超人不會飛.flac'},
    {name: '周杰倫 - 愛的飛行日記', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/09 周杰倫 - 愛的飛行日記.flac'},
    {name: '周杰倫 - 我落淚‧情緒零碎', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/08 周杰倫 - 我落淚‧情緒零碎.flac'},
    {name: '周杰倫 - 嘻哈空姐', url: 'https://files.elmle.top/jaychou/2010 - 跨时代/07 周杰倫 - 嘻哈空姐.flac'},
    {name: '周杰倫 - 退後', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/05 周杰倫 - 退後.flac'},
    {name: '周杰倫 - 白色風車', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/08 周杰倫 - 白色風車.flac'},
    {name: '周杰倫 - 紅模仿', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/06 周杰倫 - 紅模仿.flac'},
    {name: '周杰倫 - 本草綱目', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/04 周杰倫 - 本草綱目.flac'},
    {name: '周杰倫 - 心雨', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/07 周杰倫 - 心雨.flac'},
    {name: '周杰倫 - 迷迭香', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/09 周杰倫 - 迷迭香.flac'},
    {name: '周杰倫 - 菊花台', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/10 周杰倫 - 菊花台.flac'},
    {name: '周杰倫 - 千里之外', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/03 周杰倫 - 千里之外.flac'},
    {name: '周杰倫 - 聽媽媽的話', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/02 周杰倫 - 聽媽媽的話.flac'},
    {name: '周杰倫 - 夜的第七章', url: 'https://files.elmle.top/jaychou/2006 - 依然范特西/01 周杰倫 - 夜的第七章.flac'},
    {name: '周杰倫 - 床边故事', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/01 周杰倫 - 床边故事.flac'},
    {name: '周杰倫 - 前世情人', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/04 周杰倫 - 前世情人.flac'},
    {name: '周杰倫 - 英雄', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/05 周杰倫 - 英雄.flac'},
    {name: '周杰倫 - 土耳其冰淇淋', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/07 周杰倫 - 土耳其冰淇淋.flac'},
    {name: '周杰倫 - 说走就走', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/02 周杰倫 - 说走就走.flac'},
    {name: '周杰倫 - 告白气球', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/08 周杰倫 - 告白气球.flac'},
    {name: '周杰倫 - 爱情废柴', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/10 周杰倫 - 爱情废柴.flac'},
    {name: '周杰倫 - 不该', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/06 周杰倫 - 不该.flac'},
    {name: '周杰倫 - 一点点', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/03 周杰倫 - 一点点.flac'},
    {name: '周杰倫 - Now You See Me', url: 'https://files.elmle.top/jaychou/2016 - 周杰伦的床边故事/09 周杰倫 - Now You See Me.flac'},
    {name: '周杰倫 - 蘭亭序', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/07 周杰倫 - 蘭亭序.flac'},
    {name: '周杰倫 - 魔術先生', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/05 周杰倫 - 魔術先生.flac'},
    {name: '周杰倫 - 說好的幸福呢', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/06 周杰倫 - 說好的幸福呢.flac'},
    {name: '周杰倫 - 蛇舞', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/03 周杰倫 - 蛇舞.flac'},
    {name: '周杰倫 - 流浪詩人', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/08 周杰倫 - 流浪詩人.flac'},
    {name: '周杰倫 - 喬克叔叔', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/10 周杰倫 - 喬克叔叔.flac'},
    {name: '周杰倫 - 給我一首歌的時間', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/02 周杰倫 - 給我一首歌的時間.flac'},
    {name: '周杰倫 - 時光機', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/09 周杰倫 - 時光機.flac'},
    {name: '周杰倫 - 稻香', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/11 周杰倫 - 稻香.flac'},
    {name: '周杰倫 - 花海', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/04 周杰倫 - 花海.flac'},
    {name: '周杰倫 - 龍戰騎士', url: 'https://files.elmle.top/jaychou/2008 - 魔杰座/01 周杰倫 - 龍戰騎士.flac'},
    {name: '周杰倫 - 聽見下雨的聲音', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/12 周杰倫 - 聽見下雨的聲音.flac'},
    {name: '周杰倫 - 天涯過客', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/04 周杰倫 - 天涯過客.flac'},
    {name: '周杰倫 - 陽明山', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/01 周杰倫 - 陽明山.flac'},
    {name: '周杰倫 - 怎麼了', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/05 周杰倫 - 怎麼了.flac'},
    {name: '周杰倫 - 我要夏天', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/07 周杰倫 - 我要夏天.flac'},
    {name: '周杰倫 - 鞋子特大號', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/09 周杰倫 - 鞋子特大號.flac'},
    {name: '周杰倫 - 美人魚', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/11 周杰倫 - 美人魚.flac'},
    {name: '周杰倫 - 一口氣全唸對', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/06 周杰倫 - 一口氣全唸對.flac'},
    {name: '周杰倫 - 竊愛', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/02 周杰倫 - 竊愛.flac'},
    {name: '周杰倫 - 聽爸爸的話', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/10 周杰倫 - 聽爸爸的話.flac'},
    {name: '周杰倫 - 算什麼男人', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/03 周杰倫 - 算什麼男人.flac'},
    {name: '周杰倫 - 手寫的從前', url: 'https://files.elmle.top/jaychou/2014 - 哎呦，不错哦/08 周杰倫 - 手寫的從前.flac'},
    {name: '周杰倫 - 雙截棍', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/04 周杰倫 - 雙截棍.flac'},
    {name: '周杰倫 - 七里香', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/09 周杰倫 - 七里香.flac'},
    {name: '周杰倫 - 外婆', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/10 周杰倫 - 外婆.flac'},
    {name: '周杰倫 - 最後的戰役', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/05 周杰倫 - 最後的戰役.flac'},
    {name: '周杰倫 - 以父之名', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/06 周杰倫 - 以父之名.flac'},
    {name: '周杰倫 - 黑色幽默', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/02 周杰倫 - 黑色幽默.flac'},
    {name: '周杰倫 - 東風破', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/07 周杰倫 - 東風破.flac'},
    {name: '周杰倫 - 雙刀', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/08 周杰倫 - 雙刀.flac'},
    {name: '周杰倫 - 忍者', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/03 周杰倫 - 忍者.flac'},
    {name: '周杰倫 - 可愛女人', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/01 周杰倫 - 可愛女人.flac'},
    {name: '周杰倫 - 飄移', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/11 周杰倫 - 飄移.flac'},
    {name: '周杰倫 - 一路向北', url: 'https://files.elmle.top/jaychou/2005 - Initial J (頭文字D)/12 周杰倫 - 一路向北.flac'},
    {name: '周杰倫 - 忍者', url: 'https://files.elmle.top/jaychou/2001 - 范特西/04 周杰倫 - 忍者.flac'},
    {name: '周杰倫 - 對不起', url: 'https://files.elmle.top/jaychou/2001 - 范特西/07 周杰倫 - 對不起.flac'},
    {name: '周杰倫 - 雙截棍', url: 'https://files.elmle.top/jaychou/2001 - 范特西/09 周杰倫 - 雙截棍.flac'},
    {name: '周杰倫 - 上海 一九四三', url: 'https://files.elmle.top/jaychou/2001 - 范特西/06 周杰倫 - 上海 一九四三.flac'},
    {name: '周杰倫 - 簡單愛', url: 'https://files.elmle.top/jaychou/2001 - 范特西/03 周杰倫 - 簡單愛.flac'},
    {name: '周杰倫 - 開不了口', url: 'https://files.elmle.top/jaychou/2001 - 范特西/05 周杰倫 - 開不了口.flac'},
    {name: '周杰倫 - 爸 我回來了', url: 'https://files.elmle.top/jaychou/2001 - 范特西/02 周杰倫 - 爸 我回來了.flac'},
    {name: '周杰倫 - 愛在西元前', url: 'https://files.elmle.top/jaychou/2001 - 范特西/01 周杰倫 - 愛在西元前.flac'},
    {name: '周杰倫 - 安靜', url: 'https://files.elmle.top/jaychou/2001 - 范特西/10 周杰倫 - 安靜.flac'},
    {name: '周杰倫 - 威廉古堡', url: 'https://files.elmle.top/jaychou/2001 - 范特西/08 周杰倫 - 威廉古堡.flac'},
]
